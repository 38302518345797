import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PlanningTypesService, PaymentStatusService, PaymentTypesService, UserRolesService, ResignedStatusService } from './../../services';
import { fromDictionariesActions } from '../actions/dictionaries.action';

@Injectable()
export class DictionariesEffects {
    getUserRoles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromDictionariesActions.getDictionariesAction),
            mergeMap(() =>
                this.userRolesService.getUserRoles().pipe(map((response) => fromDictionariesActions.getUserRolesSuccessAction({ payload: response }))),
            ),
        );
    });

    getPaymentTypes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromDictionariesActions.getDictionariesAction),
            mergeMap(() =>
                this.paymentTypesService.getPaymentTypes().pipe(map((response) => fromDictionariesActions.getPaymentTypesSuccessAction({ payload: response }))),
            ),
        );
    });

    getPlanningTypes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromDictionariesActions.getDictionariesAction),
            mergeMap(() =>
                this.planningTypesService
                    .getPlanningTypes()
                    .pipe(map((response) => fromDictionariesActions.getPlanningTypesSuccessAction({ payload: response }))),
            ),
        );
    });

    getPaymentStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromDictionariesActions.getDictionariesAction),
            mergeMap(() =>
                this.paymentStatusService
                    .getPaymentStatus()
                    .pipe(map((response) => fromDictionariesActions.getPaymentStatusSuccessAction({ payload: response }))),
            ),
        );
    });

    getResignedStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromDictionariesActions.getDictionariesAction),
            mergeMap(() =>
                this.resignedStatusService
                    .getResignedStatuses()
                    .pipe(map((response) => fromDictionariesActions.getResignedStatusSuccessAction({ payload: response }))),
            ),
        );
    });

    constructor(
        private actions$: Actions,
        private userRolesService: UserRolesService,
        private paymentTypesService: PaymentTypesService,
        private planningTypesService: PlanningTypesService,
        private paymentStatusService: PaymentStatusService,
        private resignedStatusService: ResignedStatusService,
    ) {}
}
