import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import * as fromDictionaries from './store/reducers/dictionaries.reducer';
import { DictionariesFacade } from './store/facades/dictionaries.facade';
import { PaymentTypesService } from './services/index';
import { EffectsModule } from '@ngrx/effects';
import { DictionariesEffects } from './store/effects/dictionaries.effects';
import { PlanningTypesService } from './services/planning-type.service';
import { UserRolesService } from './services/user-roles.service';
import { PaymentStatusService } from './services/payment-status.service';
import { DidntGoService } from './services/didnt-go.service';
import { RideStatusService } from './services/ride-status.service';
import { MissingGeoPositionService } from './services/missing-geo-position.service';
import { ResignedStatusService } from './services/resigned-status.service';
import { DICTIONARIES_FEATURE_KEY } from './store/keys';

@NgModule({
    providers: [
        DictionariesFacade,
        PaymentTypesService,
        PlanningTypesService,
        UserRolesService,
        PaymentStatusService,
        DidntGoService,
        RideStatusService,
        MissingGeoPositionService,
        ResignedStatusService,
    ],
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(DICTIONARIES_FEATURE_KEY, fromDictionaries.dictionariesReducer),
        EffectsModule.forFeature([DictionariesEffects]),
    ],
})
export class DictionariesModule {}
